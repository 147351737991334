














































import { Component, Vue } from 'vue-property-decorator'
import AlbumDescriptionManager from '@/components/AlbumDescriptionManager.vue'
import { vxm } from '@/store/store.vuex'
import fb from 'firebase/app'
import Photo = fb.firestore.DocumentData
import FullscreenPhotoViewer from '@/components/FullscreenPhotoViewer.vue'
@Component({
  components: { FullscreenPhotoViewer, AlbumDescriptionManager }
})
export default class AlbumViewer extends Vue {
  created() {
    const albumId = this.$route.path.split('/').slice(-1)[0]
    this.$store.dispatch('firestore/getAlbumPhotos', albumId)
  }

  displayFullscreenImage(image: Photo) {
    this.$store.commit('app/setSelectedPhoto', image)
    this.$store.commit('app/toggleFullscreenImage', true)
  }

  get albumPhotos() {
    return vxm.firestore.albumPhotos
  }

  get albumTitle() {
    return vxm.firestore.selectedAlbumTitle
  }

  get isDisplayingFullscreenImage() {
    return vxm.app.isDisplayingFullscreenImage
  }
}
